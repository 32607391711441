import { type FactorySlice, createSlice } from './createSlice';
export interface Slice {
  id?: number | null;
  setId: (id: number | null) => void;
}
const factory: FactorySlice<Slice> = (set, _get) => ({
  setId: id => set({
    id
  })
});
const slice = <Z extends { [k in P]: any }, P extends keyof Z>(prop: P) => createSlice<Z, Slice, P>(prop, factory);
export default slice;