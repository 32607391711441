/* global RequestCredentials */
// RequestCredential https://stackoverflow.com/a/76908483

import env from '@env';
import gql from '@gql';
import type { JwtPayload } from '@modules/auth/api/utils/hasura_response';
import store from '@store';
import { devtoolsExchange } from '@urql/devtools';
import { authExchange } from '@urql/exchange-auth';
import resetStore from '@utils/resetStore';
import { createClient as createWSClient } from 'graphql-ws';
import jwtDecode, { type JwtPayload as DefaultJwtPayload } from 'jwt-decode';
import type { NextPageContext } from 'next';
import { withUrqlClient as withUrqlClientNext } from 'next-urql';
import Router from 'next/router';
import { type SSRExchange, cacheExchange, fetchExchange, subscriptionExchange } from 'urql';
import NodeWebSocket from 'ws';
const {
  hasuraEndpointPublic: hasuraEndpoint
} = env;
const refreshJwtToken = gql(`
  mutation refreshJwtToken($team_id: Int) {
    refreshJwtToken(team_id: $team_id) {
      token
    }
  }
`);
const isServer = typeof window === 'undefined';
if (!hasuraEndpoint && !isServer) throw new Error('hasuraEndpointPublic env vars are missing');
export const baseClientOptions = {
  url: hasuraEndpoint,
  maskTypename: true,
  fetchOptions: () => ({
    credentials: ('include' as any) // RequestCredentials,
  }),

  // requestPolicy: 'network-only' as RequestPolicy,
  exchanges: [devtoolsExchange, cacheExchange, fetchExchange]
};
const createSubscriptionClient = (getToken?: () => string) => createWSClient({
  url: hasuraEndpoint?.replace('https', 'wss').replace('http', 'ws'),
  retryAttempts: Number.POSITIVE_INFINITY,
  shouldRetry: () => true,
  connectionParams: async () => ({
    headers: getToken?.() ? {
      Authorization: `Bearer ${getToken()}`
    } : {}
  }),
  webSocketImpl: typeof window === 'undefined' ? NodeWebSocket : WebSocket
});

// Error when clientOptions is passed with only infered types
export const clientOptions = ({
  ssrExchange,
  suspense,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ctx
}: {
  ssrExchange: SSRExchange;
  suspense?: boolean;
  ctx?: NextPageContext;
}) => {
  // Replay client cookie on SSR
  // const cookie = ctx?.req?.headers?.cookie;
  // RANT: There's no way to call refreshJwt on SSR and pass the new acquired refresh_token
  // cookie value to the client, making the client stale with the previous refresh_token
  // We could leverage getServerSideProps or implement custom ssrExchange but I
  // don't it's worth the ordeal considering our app is a Dashboard and benefits
  // of SSR questionable, thus we're actually passing {ssr: false } to
  // withUrqlClient

  // let parsedCookie;
  // if (cookie) {
  //   parsedCookie = parse(cookie);
  // }

  const fetchOptions = () => ({
    credentials: ('include' as RequestCredentials)
    // ...(cookie ? { headers: { cookie } } : {}),
  });

  let token: string | null | undefined;
  return {
    ...baseClientOptions,
    // Replace baseClientOptions' fetchOption with the above one
    fetchOptions,
    ...(suspense ? {
      suspense: true
    } : {}),
    exchanges: [devtoolsExchange, cacheExchange, ssrExchange, authExchange(async utils => {
      token = store.getState().user.token;
      return {
        addAuthToOperation: operation => utils.appendHeaders(operation, {
          ...(token ? {
            Authorization: `Bearer ${token}`
          } : {})
          // ...(cookie ? { cookie } : {}),
        }),

        willAuthError: operation => {
          token = store.getState().user.token;

          // Some operations should never cause a refreshAuth, like login
          const shouldRefresh = !operation.query.definitions?.some(def => def.kind === 'OperationDefinition' && ['AuthSignInOrSignUp', 'AuthForgotPassword', 'LogScanPage', 'NewSession', 'qrcodeSubscription'].includes(def?.name?.value || ''));
          const tokenData = token ? jwtDecode<DefaultJwtPayload & JwtPayload>(token) : null;

          // Token has expired
          if (tokenData?.exp && new Date(tokenData.exp * 1000) < new Date()) {
            return true;
          }
          if (!token) {
            return shouldRefresh;
          }
          return false;
        },
        didAuthError: (error, _operation) => {
          // check if the error was an auth error (this can be implemented in various ways, e.g. 401 or a special error code)
          const didError = error.graphQLErrors.some(e => ['invalid-jwt']?.includes((e.extensions?.code as string)));
          return didError;
        },
        refreshAuth: async () => {
          // We only want to run refresh on the client because we won't be
          // able to pass the new refresh_token value back to the client
          // cookie.
          // SSR is disabled but nonetheless we've this check here to ensure.
          if (isServer) return;

          // We're refreshing so let's invalidate previous tokens otherwise it
          // will be forwarded to the mutate below via addAuthOperation
          // Authorization header injection and fail to refresh (Hasura
          // automatically checks the Authorization header even if our actual
          // Hasura Action implementation doesn't)
          token = null;
          const team_id = store.getState().team.id;
          const result = await utils.mutate(refreshJwtToken, {
            ...(team_id ? {
              team_id
            } : {})
          }, {
            fetchOptions
          });
          token = result?.data?.refreshJwtToken?.token;
          const tokenData = token ? jwtDecode<JwtPayload>(token) : null;
          store.setState(state => ({
            ...state,
            user: {
              ...state.user,
              token,
              id: tokenData?.user?.id
            },
            team: {
              ...state.team,
              id: tokenData?.user?.team
            },
            compliance: {
              ...state.compliance,
              id: tokenData ? state.compliance.id : null
            }
          }));
          if (!token) {
            resetStore.all();
            Router.push('/login');
          }
          return;
        }
      };
    }), subscriptionExchange({
      forwardSubscription: request => {
        const input = {
          ...request,
          query: request.query || ''
        };
        return {
          subscribe: sink => ({
            unsubscribe: createSubscriptionClient(() => (token as string)).subscribe(input, sink)
          })
        };
      }
    }), fetchExchange]
  };
};
export const withUrqlClient = withUrqlClientNext((ssrExchange, ctx) => clientOptions({
  ssrExchange,
  ctx
}),
// As we can't really process authenticated queries on SSR, enabling it would
// only increase time to first byte
{
  ssr: false
});
export default withUrqlClient;