import '@whatshq/ui/styles.css';
import TwinGlobalStyles from '@components/TwinGlobalStyles';
import I18n from '@contexts/I18n';
import { cache } from '@emotion/css';
import { CacheProvider } from '@emotion/react';
import env from '@env';
import { withUrqlClient } from '@graphql';
import { HighlightInit } from '@highlight-run/next/client';
import { ErrorBoundary } from '@highlight-run/next/client';
import { Toaster } from '@whatshq/ui';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/compat/router';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect } from 'react';
import { Monitoring } from 'react-scan/monitoring/next';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const {
  postHogKey
} = env;

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== 'undefined') {
  posthog.init(postHogKey, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
    // Enable debug mode in development
    loaded: posthog => {
      if (process.env.NODE_ENV === 'development') posthog.debug();
    }
  });
}
type AppPropsWithLayout = AppProps & {
  Component: AppProps['Component'] & {
    getLayout?: any;
  };
  pageProps: AppProps['pageProps'] & {
    lngDict: any;
    lng: string;
  };
  resetUrqlClient: () => void;
};
const App = ({
  Component,
  pageProps,
  resetUrqlClient
}: AppPropsWithLayout) => {
  const getLayout = Component.getLayout || (({
    page
  }) => page);
  const router = useRouter();
  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture('$pageview');
    router?.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router?.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);
  return _jsxs(_Fragment, {
    children: [_jsx(Monitoring, {
      apiKey: "Pt8GUqUV0F0gZvummbK88gC0oK0xOCnq" // Safe to expose publically
      ,
      url: "https://monitoring.react-scan.com/api/v1/ingest",
      commit: process.env.GIT_COMMIT_HASH // optional but recommended
      ,
      branch: process.env.GIT_BRANCH // optional but recommended
    }), _jsx(HighlightInit, {
      excludedHostnames: ['localhost'],
      projectId: 'neyxlxd4',
      tracingOrigins: true,
      environment: process.env.NODE_ENV,
      networkRecording: {
        enabled: true,
        recordHeadersAndBody: true,
        urlBlocklist: []
      }
    }), _jsx(ErrorBoundary, {
      showDialog: process.env.NODE_ENV !== 'development',
      children: _jsx(PostHogProvider, {
        client: posthog,
        children: _jsx(CacheProvider, {
          value: cache,
          children: _jsxs(I18n, {
            lngDict: pageProps?.lngDict,
            locale: pageProps?.lng,
            children: [_jsx(TwinGlobalStyles, {}), getLayout({
              props: {
                resetUrqlClient
              },
              page: _jsx(Component, {
                ...pageProps
              })
            }), _jsx(Toaster, {})]
          })
        })
      })
    })]
  });
};
export default withUrqlClient(App);