interface Environment {
  jwtSecret: string;
  emailUser: string;
  sendgridApiKey: string;
  deploymentBranch: string;
  hasuraEndpoint: string;
  hasuraEndpointPublic: string;
  hasuraSecret: string;
  iuguApiToken: string;
  postHogKey: string;
  iuguAccountId: string;
  twilioAccountSid: string;
  twilioAuthToken: string;
  whatsAppNumber: string;
  zapperAuthLink: string;
  speechCredentialPrivateKey: string;
  speechCredentialClientEmail: string;
  speechCredentialProjectId: string;
  s3Endpoint: string;
  s3MediaBucket: string;
  awsRegion: string;
  awsAccessKeyId: string;
  awsSecretAccessKey: string;
  whatsappApiUrl: string;
}
type ENV = {
  [key: string]: string | undefined;
};
const deploymentBranch = process.env.NEXT_PUBLIC_DEPLOYMENT_BRANCH;
const isServer = typeof window === 'undefined';

// Vercel allows only for 'Preview' environments, in order to have
// a `staging` env, let's check the deployment branch and use a specific
// env var to bypass the default expected env var name
const isStaging = deploymentBranch === 'staging';
const logAndScream = (env: string) => {
  const msg = `The env ${env} is missing, set it to run the application.`;
  console.error({
    env,
    pEnv: process.env?.[env]
  }, msg);
  !isServer && alert(msg);
};
const checkEnvValidContext = (env: ENV) => {
  const key = Object.keys(env)[0];
  const value = env[key];
  const isPublic = Boolean(value && value?.indexOf('NEXT_PUBLIC_') > -1);
  // All envs should be checked on Server, only Public envs should be checked on Browser
  const shouldCheck = isServer ? true : Boolean(isPublic);
  const isSet = () => Boolean(process.env?.[key]);
  return shouldCheck ? isSet() : true;
};
const checkValidEnvs = (envs: ENV[]) => {
  envs.forEach(env => {
    const key = Object.keys(env)[0];
    const isValid = checkEnvValidContext(env);
    !isValid && logAndScream(key);
  });
};

// We need to explictly set the env full name with process.env.ENV_NAME
// otherwise Babel wouldn't transpile/replace these values on the client-side
// Doing dynamic lookups with a variable like `process.env[ENV_NAME]`
// won't properly inline the values
checkValidEnvs([{
  SECRET: process.env.SECRET
}, {
  EMAIL_USER: process.env.EMAIL_USER
}, {
  SENDGRID_API_KEY: process.env.SENDGRID_API_KEY
}, {
  HASURA_ENDPOINT: process.env.HASURA_ENDPOINT
}, {
  NEXT_PUBLIC_HASURA_ENDPOINT: process.env.NEXT_PUBLIC_HASURA_ENDPOINT
}, {
  NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY
}, {
  HASURA_SECRET: process.env.HASURA_SECRET
}, {
  IUGU_API_TOKEN: process.env.IUGU_API_TOKEN
}, {
  IUGU_ACCOUNT_ID: process.env.IUGU_ACCOUNT_ID
}, {
  TWILIO_ACCOUNT_SID: process.env.TWILIO_ACCOUNT_SID
}, {
  TWILIO_AUTH_TOKEN: process.env.TWILIO_AUTH_TOKEN
}, {
  SPEECH_CREDENTIAL_PRIVATE_KEY: process.env.SPEECH_CREDENTIAL_PRIVATE_KEY
}, {
  SPEECH_CREDENTIAL_CLIENT_EMAIL: process.env.SPEECH_CREDENTIAL_CLIENT_EMAIL
}, {
  SPEECH_CREDENTIAL_PROJECT_ID: process.env.SPEECH_CREDENTIAL_PROJECT_ID
}, {
  WHATSAPP_NUMBER: process.env.WHATSAPP_NUMBER
}, {
  ZAPPER_AUTH_LINK: process.env.ZAPPER_AUTH_LINK
}, {
  S3_MEDIA_BUCKET: process.env.S3_MEDIA_BUCKET
}, {
  AWS_REGION: process.env.AWS_REGION
}, {
  AWS_ACCESS_KEY_ID: process.env.AWS_ACCESS_KEY_ID
}, {
  AWS_SECRET_ACCESS_KEY: process.env.AWS_SECRET_ACCESS_KEY
}]);
const envs = ({
  jwtSecret: process.env.SECRET,
  emailUser: process.env.EMAIL_USER,
  deploymentBranch: process.env.NEXT_PUBLIC_DEPLOYMENT_BRANCH,
  sendgridApiKey: process.env.SENDGRID_API_KEY,
  hasuraEndpoint: isStaging ? process.env.STAGING_HASURA_ENDPOINT : process.env.HASURA_ENDPOINT,
  hasuraEndpointPublic: isStaging ? process.env.NEXT_PUBLIC_STAGING_HASURA_ENDPOINT : process.env.NEXT_PUBLIC_HASURA_ENDPOINT,
  hasuraSecret: isStaging ? process.env.STAGING_HASURA_SECRET : process.env.HASURA_SECRET,
  postHogKey: process.env.NEXT_PUBLIC_POSTHOG_KEY,
  iuguApiToken: process.env.IUGU_API_TOKEN,
  iuguAccountId: process.env.IUGU_ACCOUNT_ID,
  twilioAccountSid: process.env.TWILIO_ACCOUNT_SID,
  twilioAuthToken: process.env.TWILIO_AUTH_TOKEN,
  speechCredentialPrivateKey: process.env.SPEECH_CREDENTIAL_PRIVATE_KEY,
  speechCredentialClientEmail: process.env.SPEECH_CREDENTIAL_CLIENT_EMAIL,
  speechCredentialProjectId: process.env.SPEECH_CREDENTIAL_PROJECT_ID,
  whatsAppNumber: process.env.WHATSAPP_NUMBER,
  zapperAuthLink: process.env.ZAPPER_AUTH_LINK,
  s3Endpoint: process.env.S3_ENDPOINT,
  s3MediaBucket: process.env.S3_MEDIA_BUCKET,
  awsRegion: process.env.AWS_REGION,
  awsAccessKeyId: process.env.AWS_ACCESS_KEY_ID,
  awsSecretAccessKey: process.env.AWS_SECRET_ACCESS_KEY
} as Environment);
export default envs;