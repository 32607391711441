import type { GetState, PartialState, SetState, State } from 'zustand';
const noop = (..._: unknown[]): void => {
  /* noop */
};
export type Setter<T extends State> = (s: Partial<T> | ((prev: T) => Partial<T>), replace?: boolean) => void;
export type FactorySlice<S extends State> = (set: Setter<S>, get: () => S) => S;
export type Factory<Z extends { [k in P]: S }, S extends State, P extends keyof Z> = (set: Setter<S>, get: () => S) => S;

// https://gist.github.com/kyle-mccarthy/cae2df1089c71b9d6f5eb55992a15474
// Zustand recommnds splitting the store into separate "slices", but doesn't
// really provide a great way to do this. [^slices]
//
// This allows for easily creating slices that are encapsulated as an object in
// the primary store. `createSlice` does this by re-scoping the get and set
// functions to the slice based on the property name passed as the first arg.
//
// # Example
//
// ## Create the slice for the primary store
// ```
// interface UserSlice {
//   id?: number;
//   email?: string;
//   setId: (id: number) => void;
//   setEmail: (email: string) => void;
// }
//
// const userFactory: Factory<AddressSlice> = (set, _get) => ({
//   setId: (id: number) => set({ id }),
//   setEmail: (email: string) => set({ email }),
// });
//
// const createUserSlice = createSlice("user", userFactory);
// ```
//
// ## Create the store and include your slice
// ```
// import { create } from "zustand";
//
// interface Store {
//   user: UserSlice;
//   ...
// }
//
// const store = create((set, get) => ({
//   user: createUserSlice(set, get),
// }));
// ```
//
// [^slices]: https://github.com/pmndrs/zustand/wiki/Splitting-the-store-into-separate-slices
export const createSlice = <Z extends { [k in P]: S }, S extends State, P extends keyof Z>(property: P, factory: FactorySlice<S>): ((set: SetState<Z>, get: GetState<Z>) => S) => (set, get) => {
  const getter = (): S => (get()[property] as S);
  const setter: Setter<S> = (arg, replace) => {
    const prev = get()[property];
    const next = typeof arg === 'function' ? arg((prev as S)) : arg;
    if (next === prev) {
      return noop();
    }
    if (replace) {
      return set(({
        [property]: (next as S)
      } as PartialState<Z>));
    }
    return set(({
      [property]: ({
        ...prev,
        ...next
      } as S)
    } as PartialState<Z>));
  };
  return factory(setter, getter);
};