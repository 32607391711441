import { type FactorySlice, createSlice } from './createSlice';
export interface Slice {
  id?: number | null;
  token?: string | null;
  setToken: (token: string | null) => void;
  setId: (id: number | null) => void;
  language?: string | null;
  setLanguage: (language: string | null) => void;
}
const factory: FactorySlice<Slice> = (set, _get) => ({
  setToken: token => set({
    token
  }),
  setId: id => set({
    id
  }),
  setLanguage: language => set({
    language
  })
});
const slice = <Z extends { [k in P]: any }, P extends keyof Z>(prop: P) => createSlice<Z, Slice, P>(prop, factory);
export default slice;