import isNotNull from '@utils/isNotNull';
import produce, { type Draft } from 'immer';
import { pipe } from 'sanctuary';
import create from 'zustand';
import type { State, StateCreator } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import complianceSlice, { type Slice as ComplianceSlice } from './Compliance';
import teamSlice, { type Slice as TeamSlice } from './Team';
import userSlice, { type Slice as UserSlice } from './User';
const isObject = item => item && typeof item === 'object' && !Array.isArray(item);
const deepAssign = (target, source) => {
  const output = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    for (const key of Object.keys(source)) {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, {
          [key]: source[key]
        });else output[key] = deepAssign(target[key], source[key]);
      } else {
        Object.assign(output, {
          [key]: source[key]
        });
      }
    }
  }
  return output;
};
const log = <T extends State,>(config: StateCreator<T>) => (set, get, api) => config(args => {
  console.log('  applying', args);
  set(args);
  console.log('  new state', get());
}, get, api);
const immer = <T extends State,>(config: StateCreator<T>): StateCreator<T> => (set, get, api) => config((partial, replace) => {
  const nextState = typeof partial === 'function' ? produce((partial as (state: Draft<T>) => T)) : (partial as T);
  return set((nextState as T), replace);
}, get, api);
export interface Store extends State {
  compliance: ComplianceSlice;
  user: UserSlice;
  team: TeamSlice;
}
const ENABLE_LOG = process.env.NEXT_PUBLIC_ENABLE_LOG ?? false;
const middlewares = [devtools, ENABLE_LOG ? log : null, immer, create].filter(isNotNull);
console.log({
  middlewares
});
const createStore: typeof create = pipe(middlewares);
export const useStore = createStore<Store>(persist((set, get) => ({
  // XXX: these typings are ugly and verbose, needs improvement
  compliance: complianceSlice<Store, 'compliance'>('compliance')(set, get),
  user: userSlice<Store, 'user'>('user')(set, get),
  team: teamSlice<Store, 'team'>('team')(set, get)
}), {
  name: 'zapper/store',
  // unique name
  getStorage: () => sessionStorage,
  merge: (persistedState, currentState) => deepAssign(currentState, persistedState),
  partialize: state => Object.fromEntries(Object.entries(state).filter(([key]) => !['publicRoute'].includes(key)))
}));
export default useStore;