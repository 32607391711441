import store from '@store';
const resetStore = {
  all: () => store.setState(state => {
    state.compliance.id = null;
    state.user.id = null;
    state.user.token = null;
    state.team.id = null;
  }),
  compliance: () => store.setState(state => {
    state.compliance.id = null;
  }),
  user: () => store.setState(state => {
    state.user.id = null;
  }),
  token: () => store.setState(state => {
    state.user.token = null;
  }),
  team: () => store.setState(state => {
    state.team.id = null;
  })
};
export default resetStore;